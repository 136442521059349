export class EventCounter {
  private events: number = 0;

  constructor(private callback: (eventsPerSecond: number) => void) {
    setInterval(() => this.onTimerElapsed(), 1000);
  }

  onEvent() {
    this.events++;
  }
  
  onTimerElapsed() {
    this.callback(this.events);
    this.events = 0;
  }
}
