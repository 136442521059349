import { EventCounter as EventCounter } from './util/EventCounter';
import { Game } from './game/Game';
import { Canvas } from './util/Canvas';
import { Settings } from './game/Settings';

const htmlCanvas = document.getElementById('canvas') as HTMLCanvasElement;
const fpsDisplay = document.getElementById('fps') as HTMLSpanElement;
const tpsDisplay = document.getElementById('tps') as HTMLSpanElement;
const context = htmlCanvas.getContext("2d");
if (!context || !fpsDisplay) {
  throw new Error("Required element not found");
}

const fpsCalc = new EventCounter(v => fpsDisplay.innerText = `FPS: ${v}`);
const tpsCalc = new EventCounter(v => tpsDisplay.innerText = `TPS: ${v}`);
const canvas = new Canvas(htmlCanvas, context);
const game = new Game(canvas);

setInterval(() => { game.onTick(); tpsCalc.onEvent(); }, 15);
setInterval(() => game.onEventually(), 100);

const gameLoop = () => {

  fpsCalc.onEvent();

  if (Settings.ClearScreen) {
    context?.clearRect(0, 0, canvas.width, canvas.height);
  }

  game.onDraw();
  window.requestAnimationFrame(gameLoop);

}

window.requestAnimationFrame(gameLoop);