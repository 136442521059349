import { Canvas } from '../util/Canvas';
import { Node } from './Node';
import { Edge } from '../model/Edge';
import { Settings } from './Settings';

export class Game {

  private nodes: Node[] = [];

  private edges: Edge[] = [];

  private maxDominance: number = 1;
  
  constructor(private canvas: Canvas) {
    const nodeCount = canvas.width * canvas.height * Settings.NodeCount * window.devicePixelRatio;
    const nodes = this.createNodes({ count: nodeCount, initial: true });
    this.nodes.push(...nodes);
  }

  onTick(): void {
    this.moveNodes();
  }

  onEventually() : void {
    this.cycleOfLife();
    this.calculateEdges();
  }

  onDraw(): void {
    this.nodes.forEach(p => this.canvas.drawPoint(p, p.color));
    this.edges.forEach(e => this.canvas.drawEdge(e));
  }

  private createNodes(opts: {count: number, initial: boolean}): Node[] {
    const maxDominance = this.maxDominance * (1.0 + Settings.DominanceProbability);

    if (opts.initial) {
      return Array.from({ length: opts.count }).map(i => Node.within(this.canvas, 0, Settings.SpeedFactor, maxDominance));
    } else {
      return Array.from({ length: opts.count }).map(i => Node.onEdge(this.canvas, 0, Settings.SpeedFactor, maxDominance));
    }
  }

  private moveNodes() {
    this.nodes.forEach(node => {
      node.move();
    });
  }

  private cycleOfLife(): void {
    const deadNodes = this.nodes.filter(n => !n.isWithin(this.canvas));
    this.nodes = this.nodes.filter(n => n.isWithin(this.canvas));

    const newNodes = this.createNodes({count: deadNodes.length, initial: false});
    this.nodes.push(...newNodes);

    if (newNodes.length > 0) {
      this.maxDominance = Math.max(this.maxDominance, ...newNodes.map(n => n.dominance));
    }
  }

  private calculateEdges() {
    this.edges = [];

    if (this.nodes.length < 2) {
      return;
    }

    for (let p = 0; p < this.nodes.length - 1; p++) {
      for (let q = p+1; q < this.nodes.length; q++) {
        const n1 = this.nodes[p];
        const n2 = this.nodes[q];
        if (n1.distanceTo(n2) < Settings.LineDistance) {
          if (n2.dominance > n1.dominance) {
            n1.color = n2.color;
            n1.dominance = n2.dominance;
          }
          else { 
            n2.color = n1.color;
            n2.dominance = n1.dominance;
          }
          this.edges.push(new Edge(n1, n2));
        }
      }
    }
  }
}
