import { Point } from '../model/Point';
import { Bounds } from '../model/Bounds';
import { Random } from '../util/Random';

export class Node implements Point {

  x: number;

  y: number;

  xspeed: number;

  yspeed: number;

  color: string;

  dominance: number;

  constructor(x: number, y: number, xspeed: number = 0, yspeed: number = 0, dominance: number = 1) {
    this.x = x;
    this.y = y;
    this.xspeed = xspeed;
    this.yspeed = yspeed;
    this.color = Random.color();
    this.dominance = dominance;
  }

  static within(bounds: Bounds, offset: number = 100, speed: number = 1, maxDominance: number = 1) {
    const dominance = Math.random() * maxDominance;
    const speedx = 2 * Math.random() - 1;
    const speedy = 2 * Math.random() - 1;
    return new Node(Math.random() * bounds.width, Math.random() * bounds.height, speedx, speedy, dominance);
  }

  static onEdge(bounds: Bounds, offset: number = 100, speed: number = 1, maxDominance: number = 1) {
    const dominance = Math.random() * maxDominance;

    const edge = Math.floor(Math.random() * 4);
    const rnd = 2 * Math.random() - 1;
    const absRnd = Math.random();
    switch (edge) {
      case 0: return new Node(Math.random() * bounds.width, offset, rnd * speed, absRnd * speed, dominance);
      case 1: return new Node(bounds.width - offset, Math.random() * bounds.height, -absRnd * speed, rnd * speed, dominance);
      case 2: return new Node(Math.random() * bounds.width, bounds.height - offset, rnd * speed, -absRnd * speed, dominance);
      case 3: return new Node(offset, Math.random() * bounds.height, absRnd * speed, rnd * speed, dominance);
    }
    throw new Error('Random went wrong...');
  }

  move(): void {
    this.x += this.xspeed;
    this.y += this.yspeed;
  }

  isWithin(bounds: Bounds) {
    return this.x >= 0 && this.x < bounds.width
      && this.y >= 0 && this.y < bounds.height;
  }

  distanceTo(other: Point): number {
    return Math.sqrt(Math.pow(this.x - other.x, 2) + Math.pow(this.y - other.y, 2));
  }
}