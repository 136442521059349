export class Random {
  static color(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (var i = 0; i < 3; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  static bool(): boolean {
    return Math.random() >= 0.5;
  }
}