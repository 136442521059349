import { Bounds } from '../model/Bounds';
import { Point } from '../model/Point';
import { Edge } from '../model/Edge';

export class Canvas implements Bounds {

  width: number;
  height: number;

  constructor(private canvas: HTMLCanvasElement,
    private context: CanvasRenderingContext2D) {
    this.width = canvas.width;
    this.height = canvas.height;
  }

  drawPoint(point: Point, color: string) {
    this.context.fillStyle = color;
    this.context.fillRect(point.x - 1.5, point.y - 1.5, 3, 3);
  }

  drawText(text: string) {
    this.context.font = "30px Arial";
    this.context.fillStyle = '#fff';
    this.context.fillText(text, 100, 100);
  }

  drawEdge(edge: Edge) {
    this.context.strokeStyle = edge.node1.color;
    this.context.beginPath();
    this.context.moveTo(edge.node1.x, edge.node1.y);
    this.context.lineTo(edge.node2.x, edge.node2.y);
    this.context.stroke();
  }
}
